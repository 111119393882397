import { Conversation, LinearTurnsTranscript, TemplatedPrompt, Transcription, TranscriptUnit, SpeakerTurn } from './common_db';

export function formatUD(ud: number): string {
  return '$' + (ud / 1000 / 1000).toFixed(2);
}

export function transcriptionStoragePath(conversationID: string, transcriptionID: string): string {
  return `transcriptions/${conversationID}/${transcriptionID}.json`;
}

type TurnStrategy = 'audiophile' | 'cliffhanger';

const TURN_BOUNDARY_GAP = 0.5; // Gap of 0.5 seconds suggests natural turn boundary
const CONTINUOUS_SPEECH_GAP = 0.2; // Words within 0.2 seconds are considered continuous speech

function isTerminalPunctuation(word: string): boolean {
  return word.endsWith('.') || word.endsWith('?') || word.endsWith('!');
}

function shouldMergeUnits(first: TranscriptUnit, second: TranscriptUnit): boolean {
  // Same speaker, close in time
  return first.speaker === second.speaker && 
         (second.start - first.end) <= CONTINUOUS_SPEECH_GAP;
}

function audiophileChunking(sortedUnits: TranscriptUnit[]): TranscriptUnit[][] {
  const speakerSegments: TranscriptUnit[][] = [];
  let curSegment: TranscriptUnit[] = [];
  
  for (const unit of sortedUnits) {
    if (curSegment.length === 0 || curSegment[curSegment.length - 1].speaker === unit.speaker) {
      curSegment.push(unit);
    } else {
      speakerSegments.push(curSegment);
      curSegment = [unit];
    }
  }
  
  if (curSegment.length > 0) {
    speakerSegments.push(curSegment);
  }
  
  return speakerSegments;
}

function cliffhangerChunking(sortedUnits: TranscriptUnit[]): TranscriptUnit[][] {
  const speakerSegments: TranscriptUnit[][] = [];
  let curSegment: TranscriptUnit[] = [];
  let pendingSegment: TranscriptUnit[] | null = null;
  
  for (const unit of sortedUnits) {
    // If we're starting fresh
    if (curSegment.length === 0) {
      curSegment.push(unit);
      continue;
    }
    
    const lastUnit = curSegment[curSegment.length - 1];
    const timeSinceLastUnit = unit.start - lastUnit.end;
    // console.log(unit.start, unit.end);
    // If same speaker, almost always merge (unless there's a huge gap)
    if (unit.speaker === lastUnit.speaker) {
      curSegment.push(unit);
      continue;
    }
    
    // Different speaker - potential interruption
    // Only consider it an interruption if:
    // 1. It's close in time to the current speech
    // 2. The current speaker hasn't finished their thought (no terminal punctuation)
    if (timeSinceLastUnit < TURN_BOUNDARY_GAP) {
      if (pendingSegment) {
        // If the pending speaker is the same as this unit and close in time, merge them
        if (pendingSegment[0].speaker === unit.speaker) {
          pendingSegment.push(unit);
        } else {
          // Otherwise commit everything and start fresh
          speakerSegments.push(curSegment);
          speakerSegments.push(pendingSegment);
          curSegment = [unit];
          pendingSegment = null;
        }
      } else {
        // Start a new pending interruption
        pendingSegment = [unit];
      }
    } else {
      // Not an interruption - clean break between speakers
      // Commit any pending interruption first
      if (pendingSegment) {
        speakerSegments.push(curSegment);
        speakerSegments.push(pendingSegment);
        curSegment = [unit];
        pendingSegment = null;
      } else {
        speakerSegments.push(curSegment);
        curSegment = [unit];
      }
    }
  }
  
  // Handle any remaining segments
  if (curSegment.length > 0) {
    speakerSegments.push(curSegment);
    if (pendingSegment) {
      speakerSegments.push(pendingSegment);
    }
  }
  
  return speakerSegments;
}

function mergeSameSpeakerSegments(segments: TranscriptUnit[][]): TranscriptUnit[][] {
  const mergedSegments: TranscriptUnit[][] = [];
  let currentSegment: TranscriptUnit[] | null = null;

  for (const segment of segments) {
    if (!currentSegment) {
      currentSegment = segment;
      continue;
    }

    if (currentSegment[0].speaker === segment[0].speaker) {
      // Merge this segment into current
      currentSegment = currentSegment.concat(segment);
    } else {
      // Different speaker, commit current and start new
      mergedSegments.push(currentSegment);
      currentSegment = segment;
    }
  }

  if (currentSegment) {
    mergedSegments.push(currentSegment);
  }

  return mergedSegments;
}

export function transcriptToTurns(
  transcription: Transcription, 
  strategy: TurnStrategy = 'cliffhanger'
): LinearTurnsTranscript {
  console.log("IN TRANSCRIPT TO TURNS");
  // Sort transcript units by start time, then end time
  const sortedUnits = [...transcription.transcript].sort((a, b) => {
    if (a.start !== b.start) {
      return a.start - b.start;
    }
    return a.end - b.end;
  });

  // Choose chunking strategy
  const speakerSegments = strategy === 'audiophile' 
    ? audiophileChunking(sortedUnits)
    : cliffhangerChunking(sortedUnits);

  // Merge consecutive segments from same speaker
  const mergedSegments = mergeSameSpeakerSegments(speakerSegments);

  const formattedSegments = mergedSegments.map((segment) => {
    return {
      speaker: segment[0].speaker,
      start: segment[0].start,
      end: segment[segment.length - 1].end,
      contents: segment
    }
  });

  const {transcript, ...rest} = transcription;
  return {...rest, turns: formattedSegments};
}

export function textFromLinearTurnsTranscript(transcript: LinearTurnsTranscript): string {
  return transcript.turns.map(turn => `${turn.speaker}\n${turn.contents.map(unit => unit.word).join(' ')}`).join('\n');
}

export async function textFromTemplatedPrompt(
  templatedPrompt: TemplatedPrompt, 
  get: (convo: Conversation) => Promise<Transcription | null>
): Promise<string> {
  let placeholderCount = (templatedPrompt.template.templateText.match(/\$\{[^}]*\}/g) || []).length;
  if (placeholderCount !== templatedPrompt.values.length) {
    throw new Error(`Expected ${placeholderCount} values but got ${templatedPrompt.values.length}`);
  }

  const values = await Promise.all(
    templatedPrompt.values.map(async (value) => {
      if (typeof value === 'string') {
        return value;
      }
      const transcription = await get(value);
      if (!transcription) {
        throw new Error(`Transcription not found for conversation ${value.id}`);
      }
      return textFromLinearTurnsTranscript(transcriptToTurns(transcription))
    }) 
  )

  let result = templatedPrompt.template.templateText;
  values.forEach((value) => {
    result = result.replace(/\$\{[^}]*\}/, value);
  });


  return result;
}

/**
 * Converts a LinearTurnsTranscript back to a full Transcription by 
 * flattening the turns into the `transcript` array.
 */
export function linearTranscriptToTranscription(linear: LinearTurnsTranscript): Transcription {
  const { turns, ...rest } = linear;
  // Flatten the turns. (Assuming turns are in order.)
  const transcript = turns.reduce<TranscriptUnit[]>((acc, turn) => {
    return acc.concat(turn.contents);
  }, []);
  return { ...rest, transcript };
}

/**
 * Given a SpeakerTurn, returns a new SpeakerTurn whose `speaker` field is updated on both
 * the turn level and on every TranscriptUnit in that turn.
 */
export function updateSpeakerNameInTurn(turn: SpeakerTurn, newSpeaker: string): SpeakerTurn {
  const updatedContents = turn.contents.map(unit => ({ ...unit, speaker: newSpeaker }));
  return { ...turn, speaker: newSpeaker, contents: updatedContents };
}


/**
 * Updates the entire transcript by replacing every turn in which the speaker exactly equals
 * `originalSpeaker` with an updated turn that has the speaker name set to `newSpeaker`.
 * Returns a new full Transcription.
 */
export function updateAllSpeakersInTranscript(
  linear: LinearTurnsTranscript,
  originalSpeaker: string,
  newSpeaker: string
): Transcription {
  const updatedTurns = linear.turns.map(turn =>
    turn.speaker === originalSpeaker ? updateSpeakerNameInTurn(turn, newSpeaker) : turn
  );
  return linearTranscriptToTranscription({ ...linear, turns: updatedTurns });
}

/**
 * Updates all discord variants in the transcript. It will replace any turn with a speaker matching
 * the same base name as originalSpeaker (for example, if originalSpeaker is "username_2", it will
 * match "username", "username_1", "username_2", etc.) with a new turn whose
 * speaker is set to `newSpeaker`.
 * Returns a new full Transcription.
 */
export function updateDiscordVariantsInTranscript(
  linear: LinearTurnsTranscript,
  originalSpeaker: string,
  newSpeaker: string
): Transcription {
  // Extract the base name by removing "_n" suffix if it exists
  const baseName = originalSpeaker.replace(/_\d+$/, '');
  // Match the base name exactly, or base name followed by "_" and any number
  const regex = new RegExp(`^${baseName}(?:_\\d+)?$`);
  
  const updatedTurns = linear.turns.map(turn => {
    return regex.test(turn.speaker) ? updateSpeakerNameInTurn(turn, newSpeaker) : turn
  }
    
  );
  return linearTranscriptToTranscription({ ...linear, turns: updatedTurns });
}

/**
 * Updates only one turn (the targetTurn) in the transcript by replacing its speaker name.
 * Returns a new full Transcription.
 */
export function updateSingleTurnInTranscript(
  linear: LinearTurnsTranscript,
  targetTurn: SpeakerTurn,
  index: number,
  newSpeaker: string
): Transcription {
  const updatedTurns = linear.turns.map((turn, i) =>
    i === index ? updateSpeakerNameInTurn(turn, newSpeaker) : turn
  );
  return linearTranscriptToTranscription({ ...linear, turns: updatedTurns });
}
import React, { useState, Fragment, useCallback, useRef, useEffect } from 'react';
import { 
  type SpeakerTurn,
  type Transcription,
} from '../common/common_db';
import { FireLoomStore } from '../db';
import { Annotation, findUnitInRangable } from '../common/transcripts';
import uuid4 from 'uuid4';
import './TranscriptBody.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectTranscript, selectUserID, selectConversation, setActiveTab, setTranscript } from '../pages/transcriptViewerSlice';
import { createAnnotation, selectFilteredAnnotations, setView } from './transcriptSidePanel/annotationPanelSlice';
import { AppDispatch } from '../store';
import { set } from 'zod';
import { AudioPlayerHandle } from './AudioPlayer';
import { transcriptToTurns, updateAllSpeakersInTranscript, updateDiscordVariantsInTranscript, updateSingleTurnInTranscript, updateSpeakerNameInTurn } from '../common/util';

const useAppDispatch: () => AppDispatch = useDispatch;

export const useWordBoundarySelection = () => {
    const [selectionRange, setSelectionRange] = useState<SelectionRange | null>(null);
    const animationFrameRef = useRef<number | null>(null);
    const latestSelectionRef = useRef<{
      startContainer: Element | null,
      endContainer: Element | null
    } | null>(null);
   
    const mouseMoveHandler = useCallback((e: React.MouseEvent) => {
      // console.log('mouse move start')
      if (e.buttons !== 1) {
        return;
      }
   
      const selection = document.getSelection();
      if (!selection || selection.rangeCount === 0) {
        setSelectionRange(null);
        return;
      }
      if (selection.isCollapsed) {
        setSelectionRange(null);
        return;
      }
      // Store the latest selection
      const range = selection.getRangeAt(0);
      latestSelectionRef.current = {
        startContainer: range.startContainer.parentElement,
        endContainer: range.endContainer.parentElement
      };
   
      // // Only schedule a new frame if we don't have one pending
      // if (!animationFrameRef.current) {
      //   animationFrameRef.current = requestAnimationFrame(() => {
          // console.log('animation frame start')
          // Process the latest selection we've stored
          if (latestSelectionRef.current) {
            const { startContainer, endContainer } = latestSelectionRef.current;
   
            // Find nearest word-id spans by traversing siblings
            const findNearestWordId = (element: Element | null): string | null => {
              // First, ensure we're at the span level
              if (!element) return null;
              if (element.tagName !== 'SPAN') {
                // If we're in a text node or other element, get to its parent span
                const parentSpan = element.closest('span');
                if (!parentSpan) return null;
                element = parentSpan;
              }
   
              // Check current element
              const currentWordId = element.getAttribute('data-word-id');
              if (currentWordId) return currentWordId;
   
              // Function to check siblings in a direction
              const checkSiblingsInDirection = (
                startElem: Element,
                getNextSibling: (elem: Element) => Element | null
              ): string | null => {
                let current = getNextSibling(startElem);
                while (current) {
                  if (current.tagName === 'SPAN') {
                    const wordId = current.getAttribute('data-word-id');
                    if (wordId) return wordId;
                  }
                  current = getNextSibling(current);
                }
                return null;
              };
   
              // Check previous siblings first (for range start)
              const previousWordId = checkSiblingsInDirection(
                element,
                elem => elem.previousElementSibling
              );
              if (previousWordId) return previousWordId;
   
              // Then check next siblings (for range end)
              const nextWordId = checkSiblingsInDirection(
                element,
                elem => elem.nextElementSibling
              );
              if (nextWordId) return nextWordId;
   
              return null;
            };
   
            const startId = findNearestWordId(startContainer);
            const endId = findNearestWordId(endContainer);
            // console.log("check end")
            if (startId && endId) {
              // Ensure the IDs are in the correct order (start should be less than end)
              const startNum = parseInt(startId);
              const endNum = parseInt(endId);
              // console.log('setting selection range')
              // flushSync(() => {
                setSelectionRange({
                  startId: Math.min(startNum, endNum),
                  endId: Math.max(startNum, endNum)
                });
              // });
            }
          }
          // Clear the animation frame ref so we can schedule another one
          // animationFrameRef.current = null;
        // });
      // }
    }, []);
   
    const removeSelection = useCallback(() => {
      setSelectionRange(null);
      window.getSelection()?.removeAllRanges();
      latestSelectionRef.current = null;
    }, [])
   
    return { selectionRange, mouseMoveHandler, removeSelection };
   };

// Add this new function for annotation positioning
const calculateAnnotationPosition = (x: number, y: number) => {
  const modalWidth = 200;
  const modalHeight = 60;
  const padding = 10;

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  let posX = x + scrollX;
  let posY = y + scrollY;

  if (posX + modalWidth + padding > viewportWidth + scrollX) {
    posX = viewportWidth + scrollX - modalWidth - padding;
  }
  if (posY + modalHeight + padding > viewportHeight + scrollY) {
    posY = posY - modalHeight - padding;
  }

  return { x: posX, y: posY };
};

interface TranscriptTurnProps {
  turn: SpeakerTurn,
  turnIndex: number,
  audioRef: React.RefObject<AudioPlayerHandle>,
  annotations: Annotation[],
  selectionRange: SelectionRange | null,
}

const TranscriptTurn = ({ 
  turn,
  turnIndex,
  audioRef,
  annotations,
  selectionRange,
}: TranscriptTurnProps) => {
  const dispatch = useAppDispatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [newSpeakerName, setNewSpeakerName] = useState(turn.speaker);
  const [isUpdating, setIsUpdating] = useState(false);

  // Get unique speaker names from the parent component
  const transcript = useSelector(selectTranscript);
  const suggestions = transcript ? Array.from(new Set(transcript.turns.map(t => t.speaker))) : [];
  const conversation = useSelector(selectConversation);
  const [docStore] = useState(new FireLoomStore());

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const isWordInSelectionRange = (wordId: number): boolean => {
    if (!selectionRange) return false;
    return wordId >= selectionRange.startId && wordId <= selectionRange.endId;
  };

  const getAnnotationForWord = (wordId: string) => {
    return annotations.find(annotation => {
      const startId = annotation.startUnit.id;
      const endId = annotation.endUnit.id;
      const currentId = parseInt(wordId);
      return currentId >= startId && currentId <= endId;
    });
  };

  const handleEditSpeakerClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setShowEditModal(true);
    setNewSpeakerName(turn.speaker);
    e.stopPropagation();
  };

  // Move the update functions inside TranscriptTurn
  const updateTranscriptInStorage = async (updatedTranscript: Transcription) => {
    if (!conversation) return;
    await docStore.updateTranscription(conversation.id, updatedTranscript);
  };

  const handleApplyAll = async () => {
    if (!transcript) return;
    setIsUpdating(true);
    try {
      const updatedTranscription = updateAllSpeakersInTranscript(transcript, turn.speaker, newSpeakerName);
      await updateTranscriptInStorage(updatedTranscription);
      await refreshTranscript();
      setShowEditModal(false);
    } catch (error) {
      console.error('Failed to update speakers:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleApplyOnly = async () => {
    if (!transcript) return;
    setIsUpdating(true);
    try {
      const updatedTranscription = updateSingleTurnInTranscript(
        transcript, 
        turn, 
        turnIndex,
        newSpeakerName
      );
      await updateTranscriptInStorage(updatedTranscription);
      await refreshTranscript();
      setShowEditModal(false);
    } catch (error) {
      console.error('Failed to update speaker:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleApplyDiscord = async () => {
    if (!transcript) return;
    setIsUpdating(true);
    try {
      const updatedTranscription = updateDiscordVariantsInTranscript(transcript, turn.speaker, newSpeakerName);
      await updateTranscriptInStorage(updatedTranscription);
      await refreshTranscript();
      setShowEditModal(false);
    } catch (error) {
      console.error('Failed to update discord variants:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const refreshTranscript = async () => {
    if (!conversation?.transcriptID) return;
    const transcription = await docStore.getTranscription(conversation.id, conversation.transcriptID);
    if (transcription) {
      const linearTranscript = transcriptToTurns(transcription);
      dispatch(setTranscript(linearTranscript));
    }
  };

  return (
    <div className="transcript-turn" style={{ position: 'relative' }}>
      <div className="turn-header">
        <span 
          className="speaker-name"
          onClick={handleEditSpeakerClick}
        >
          {turn.speaker}
        </span>
        <span 
          className="timestamp"
          onClick={() => audioRef.current?.seekToTime(turn.start)}>
          {formatTime(turn.start)}</span>
      </div>
      <p className="turn-content">
        {turn.contents.map((unit, index) => {
          const annotation = getAnnotationForWord(unit.id.toString());
          const wsInRange = isWordInSelectionRange(unit.id) && 
                           index > 0 && 
                           isWordInSelectionRange(turn.contents[index - 1].id);
          const wordInRange = isWordInSelectionRange(unit.id);
          const onClick = () => {
            console.log('click')
            if (annotation) {
              dispatch(setView({ view: 'annotation-detail', groupID: annotation.groupID, annotationID: annotation.id }));
            }
          }
          return (
            <Fragment key={unit.id}>
              {index > 0 && (
                <span
                  onClick={onClick} 
                  data-neighbor-word-id={turn.contents[index - 1].id.toString()}
                  className={`space ${wsInRange 
                    ? 'highlighted-range' 
                    : annotation ? 'annotated-text' : ''}`}
                  style={!wsInRange && annotation ? { backgroundColor: annotation.content['color'] } : undefined}
                > </span>
              )}
              <span
                id={`word-${unit.id}`} 
                onClick={onClick}
                data-word-id={unit.id.toString()}
                className={`word ${wordInRange
                  ? 'highlighted-range' 
                  : annotation ? 'annotated-text' : ''}`}
                style={!wordInRange && annotation ? { backgroundColor: annotation.content['color'] } : undefined}
              >
                {unit.word}
              </span>
            </Fragment>
          );
        })}
      </p>
      {showEditModal && (
        <EditSpeakerModal
          originalSpeaker={turn.speaker}
          newSpeaker={newSpeakerName}
          suggestions={suggestions}
          isLoading={isUpdating}
          onChange={setNewSpeakerName}
          onApplyAll={handleApplyAll}
          onApplyOnly={handleApplyOnly}
          onApplyDiscord={handleApplyDiscord}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
};

interface SelectionRange {
  startId: number;
  endId: number;
}

interface AnnotationModalProps {
  position: { x: number, y: number };
  selectionRange: SelectionRange;
  onSelect: (color: string, range: SelectionRange) => void;
  onClickOutside: () => void;
}

const AnnotationModal = ({
  position,
  selectionRange,
  onSelect,
  onClickOutside
}: AnnotationModalProps) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    
    const timeoutId = setTimeout(() => {
      document.addEventListener('mousedown', handleClickOutside);
    }, 0);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);

  const colors = ['#FFD700', '#98FB98', '#87CEFA', '#DDA0DD', '#F08080'];

  return (
    <div 
      ref={modalRef}
      className="annotation-modal"
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        zIndex: 1000,
      }}
    >
      <div className="color-options">
        {colors.map((color) => (
          <button
            key={color}
            onClick={() => onSelect(color, selectionRange)}
            className="color-option"
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
    </div>
  );
};

interface EditSpeakerModalProps {
  originalSpeaker: string,
  newSpeaker: string,
  suggestions: string[],
  isLoading?: boolean,
  onChange: (value: string) => void,
  onApplyAll: () => void,
  onApplyOnly: () => void,
  onApplyDiscord: () => void,
  onClose: () => void,
}

export const EditSpeakerModal: React.FC<EditSpeakerModalProps> = ({
  originalSpeaker,
  newSpeaker,
  suggestions,
  isLoading = false,
  onChange,
  onApplyAll,
  onApplyOnly,
  onApplyDiscord,
  onClose,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);

  // Update filtered suggestions when input changes
  useEffect(() => {
    const filtered = suggestions.filter(s => 
      s.toLowerCase().includes(newSpeaker.toLowerCase()) && 
      s.toLowerCase() !== newSpeaker.toLowerCase()
    );
    setFilteredSuggestions(filtered);
  }, [newSpeaker, suggestions]);

  // Handle keyboard navigation
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!showSuggestions) {
      if (e.key === 'ArrowDown') {
        setShowSuggestions(true);
        setSelectedIndex(0);
        e.preventDefault();
      } else if (e.key === 'Enter') {
        inputRef.current?.blur();
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        setSelectedIndex(prev => 
          prev < filteredSuggestions.length - 1 ? prev + 1 : prev
        );
        e.preventDefault();
        break;
      case 'ArrowUp':
        setSelectedIndex(prev => prev > 0 ? prev - 1 : -1);
        e.preventDefault();
        break;
      case 'Enter':
        if (selectedIndex >= 0) {
          onChange(filteredSuggestions[selectedIndex]);
          setShowSuggestions(false);
          setSelectedIndex(-1);
          e.preventDefault();
        } else {
          inputRef.current?.blur();
        }
        break;
      case 'Escape':
        setShowSuggestions(false);
        setSelectedIndex(-1);
        break;
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    onChange(suggestion);
    setShowSuggestions(false);
    setSelectedIndex(-1);
    inputRef.current?.focus();
  };

  // Close suggestions when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Focus and select input text when modal opens
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, []);

  return (
    <div
      ref={modalRef}
      className="edit-speaker-modal"
      style={{
        position: 'absolute',
        left: 0,
        top: "30px",
        zIndex: 1000,
      }}
    >
      <div className="speaker-input-container">
        <input
          ref={inputRef}
          type="text"
          value={newSpeaker}
          onChange={(e) => {
            onChange(e.target.value);
            setShowSuggestions(true);
            setSelectedIndex(-1);
          }}
          onKeyDown={handleKeyDown}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => {
            setShowSuggestions(false);
          }}
          placeholder="Enter new speaker name"
          disabled={isLoading}
        />
        {showSuggestions && filteredSuggestions.length > 0 && (
          <div className="speaker-suggestions">
            {filteredSuggestions.map((suggestion, index) => (
              <div
                key={suggestion}
                className={`suggestion-item ${index === selectedIndex ? 'selected' : ''}`}
                onClick={() => handleSuggestionClick(suggestion)}
                onMouseEnter={() => setSelectedIndex(index)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="edit-speaker-buttons">
        <button onClick={onApplyAll} disabled={isLoading}>
          {isLoading ? 'Updating...' : 'Update all turns by this speaker'}
        </button>
        <button onClick={onApplyOnly} disabled={isLoading}>
          {isLoading ? 'Updating...' : 'Update only this speaker turn'}
        </button>
        <button onClick={onApplyDiscord} disabled={isLoading}>
          {isLoading ? 'Updating...' : 'Update all discord variants'}
        </button>
      </div>
    </div>
  );
};

interface TranscriptProps {
  audioPlayerRef: React.RefObject<AudioPlayerHandle>;
}

export const TranscriptBody: React.FC<TranscriptProps> = ({audioPlayerRef}) => {
  const dispatch = useAppDispatch();
  const [docStore] = useState(new FireLoomStore());
  const transcript = useSelector(selectTranscript)
  const annotations = useSelector(selectFilteredAnnotations)
  const conversation = useSelector(selectConversation)
  const userID = useSelector(selectUserID)
  const [modalPosition, setModalPosition] = useState<{ x: number, y: number } | null>(null);
  const { selectionRange, mouseMoveHandler, removeSelection } = useWordBoundarySelection();

  // Update the handleMouseUp callback to use the annotation-specific calculation
  const handleMouseUp = useCallback((e: React.MouseEvent) => {
    const selection = document.getSelection();
    if (!selection || selection.rangeCount === 0) {
      return;
    }
    if (!selection.isCollapsed) {
      const { x, y } = calculateAnnotationPosition(e.clientX, e.clientY);
      setModalPosition({ x, y });
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const handleAnnotationSelect = useCallback(async (color: string, range: SelectionRange) => {
    if (!transcript) return;

    const newAnnotation: Omit<Annotation, 'groupID'> = {
      startUnit: findUnitInRangable(transcript.turns, range.startId),
      endUnit: findUnitInRangable(transcript.turns, range.endId),
      content: { color },
      id: uuid4(),
      authorID: userID || "",
      creationTime: Date.now(),
      lastModifiedTime: Date.now(),
      madeByHuman: true,
      conversationID: conversation?.id || "",
      tags: [],
    };
    setModalPosition(null);
    const res = await dispatch(createAnnotation(
        {loomStore: docStore, annotation: newAnnotation}
      )).unwrap();
    dispatch(setActiveTab('annotations'));
    dispatch(setView({ view: 'annotation-detail', groupID: res.groupID, annotationID: newAnnotation.id }));
    removeSelection();
  }, [transcript, userID, conversation, annotations, removeSelection]);

  return (
    <div 
      className="transcript-body" 
      onMouseUp={handleMouseUp} 
      onMouseMove={mouseMoveHandler}
    >
      {transcript ? transcript.turns.map((turn, index) => (
        <TranscriptTurn 
          key={index} 
          turn={turn}
          turnIndex={index}
          audioRef={audioPlayerRef}
          annotations={annotations}
          selectionRange={selectionRange}
        />
      )) : ""}
      {modalPosition && selectionRange && (
        <AnnotationModal
          position={modalPosition}
          selectionRange={selectionRange}
          onSelect={handleAnnotationSelect}
          onClickOutside={() => {
            setModalPosition(null)
            removeSelection()
          }}
        />
      )}
    </div>
  );
};
import {collection, CollectionReference, query, where, orderBy, doc, addDoc, getDoc, getDocs, setDoc, updateDoc, deleteDoc, limit,
  QueryDocumentSnapshot, DocumentData, startAfter, QuerySnapshot,
  or, and, onSnapshot,
  deleteField} from 'firebase/firestore';
import { firebaseAuth, firebaseDb, getCurrentUser, firebaseApp, firebaseStorage} from './firebase_config';
import { ref, uploadBytes, getBytes, getDownloadURL, FirebaseStorage, deleteObject, listAll } from 'firebase/storage';
import {SamplingOptions, DocumentValue, defaultSamplingOptions, DocumentSummary, LoomNodeValue, 
        UserValue, documentDataToValue, documentValueToData,
        userDataToValue, userValueToData,
        loomNodeDataToValue, loomNodeValueToData,
        Conversation,
        PurchaseValue, purchaseDataToValue,
        Transcription,
        PromptTemplate,
        TranscriptUnit,
        TranscriptArraySchema,
        AnnotationPromptDebugValue,
        annotationPromptDebugValueSchema,
        conversationDataToValue,
        } from './common/common_db';
import { Annotation, AnnotationGroup, AnnotationGroupDBValue } from './common/transcripts';
import { v4 as uuidv4 } from 'uuid';
import { transcriptionStoragePath } from './common/util';
import { error } from 'console';
import { ModelType } from './common/llm_clients';

export const paginationLimit = 10;

export function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export class FireLoomStore {
  docsCollectionRef: CollectionReference;
  nodesCollectionRef: CollectionReference;
  usersCollectionRef: CollectionReference;
  conversationsCollectionRef: CollectionReference;
  purchasesCollectionRef: CollectionReference;
  templatesCollectionRef: CollectionReference;
  annotationsCollectionRef: CollectionReference;
  annotationGroupsCollectionRef: CollectionReference;

  nodeCache: Record<string, LoomNodeValue> = {};
  nodeChildrenCache: Record<string, string[]> = {};

  constructor() {
    this.docsCollectionRef = collection(firebaseDb, 'loomdocs');
    this.nodesCollectionRef = collection(firebaseDb, 'loomnodes');
    this.usersCollectionRef = collection(firebaseDb, 'users');
    this.purchasesCollectionRef = collection(firebaseDb, 'purchases');
    this.conversationsCollectionRef = collection(firebaseDb, 'conversations');
    this.templatesCollectionRef = collection(firebaseDb, 'prompttemplates');
    this.annotationsCollectionRef = collection(firebaseDb, 'annotations');
    this.annotationGroupsCollectionRef = collection(firebaseDb, 'annotationgroups');
  }

  async getConversations(): Promise<Conversation[]> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      console.error('User not logged in');
      return [];
    }
    const userVal = await this.getUser(user_id); 
    if (!userVal) {
      console.error('User not found');
      return [];
    }
  
    const queryPromises: Promise<QuerySnapshot<DocumentData, DocumentData>>[] = [
      // Query for owned conversations
      getDocs(query(
        this.conversationsCollectionRef,
        where('ownerID', '==', user_id),
        orderBy('creationEpochTime', 'desc')
      )),
      // Query for participant conversations
      getDocs(query(
        this.conversationsCollectionRef,
        where('participantIDs', 'array-contains', user_id),
        orderBy('creationEpochTime', 'desc')
      )),
      // Query for public conversations
      getDocs(query(
        this.conversationsCollectionRef,
        where('visibility', '==', 'public'),
        orderBy('creationEpochTime', 'desc')
      ))
    ];
  
    // Add research query if user is a researcher
    if (userVal.isResearcher) {
      queryPromises.push(
        getDocs(query(
          this.conversationsCollectionRef,
          where('visibility', '==', 'research'),
          orderBy('creationEpochTime', 'desc')
        ))
      );
    }
    const snapshots = await Promise.all(queryPromises);
    const allDocs = snapshots.flatMap(snapshot => 
      snapshot.docs.map(doc => {
          return conversationDataToValue(doc.data(), doc.id);
      })
     );
  
    const uniqueConversations = Array.from(
      new Map(allDocs.map(conv => [conv.id, conv])).values()
    );
    return uniqueConversations;
  }

  async getTranscribedConversations(): Promise<Conversation[]> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      console.error('User not logged in');
      return [];
    }
    const userVal = await this.getUser(user_id);
    if (!userVal) {
      console.error('User not found');
      return [];
    }

    const userQuery = query(
      this.conversationsCollectionRef,
      where('ownerID', '==', user_id),
      where('transcribed', '==', true),
      orderBy('creationEpochTime', 'desc')
    );

    const participantQuery = query(
      this.conversationsCollectionRef,
      where('participantIDs', 'array-contains', user_id),
      where('transcribed', '==', true),
      orderBy('creationEpochTime', 'desc')
    );

    const publicQuery = query(
      this.conversationsCollectionRef,
      where('visibility', '==', 'public'),
      where('transcribed', '==', true),
      orderBy('creationEpochTime', 'desc')
    );

    const queries = [userQuery, publicQuery, participantQuery];
    
    if (userVal.isResearcher) {
      const researchQuery = query(
        this.conversationsCollectionRef,
        where('visibility', '==', 'research'),
        where('transcribed', '==', true),
        orderBy('creationEpochTime', 'desc')
      );
      queries.push(researchQuery);
    }

    const snapshots = await Promise.all(queries.map(q => getDocs(q)));
    
    // Combine and deduplicate conversations
    const conversationMap = new Map<string, Conversation>();
    snapshots.forEach(snapshot => {
      snapshot.docs.forEach(doc => {
        const conversation = {
          id: doc.id,
          ...doc.data()
        } as Conversation;
        conversationMap.set(conversation.id, conversation);
      });
    });

    return Array.from(conversationMap.values())
      .sort((a, b) => b.creationEpochTime - a.creationEpochTime);
  }

  async deleteTemplate(id: string): Promise<void> {
    const docRef = doc(this.templatesCollectionRef, id);
    await deleteDoc(docRef);
  }

  async getTemplates(showPublic: boolean): Promise<PromptTemplate[]> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
    const queryRef= query(
      this.templatesCollectionRef,
      showPublic ? where('isPublic', '==', true) : where('ownerID', '==', user_id),
      orderBy('creationEpochTime', 'desc'));
  
    const snapshot = await getDocs(queryRef);
    return snapshot.docs.map(doc => {
      return {
        id: doc.id,
        ownerID: doc.data().ownerID,
        isPublic: doc.data().isPublic,
        creationEpochTime: doc.data().creationEpochTime || 0,
        name: doc.data().name,
        templateText: doc.data().templateText
      };
    });
  }

  async updateTemplate(id: string, value: PromptTemplate) {
    const docRef = doc(this.templatesCollectionRef, id);
    await updateDoc(docRef, value);
  }

  async createTemplate(name: string): Promise<PromptTemplate> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
    const docVal = {
      ownerID: user_id,
      creationEpochTime: Date.now(),
      name: name,
      isPublic: false,
      templateText: ''
    };
    const docRef = await addDoc(this.templatesCollectionRef, docVal);
    return {...docVal, id: docRef.id};
  }

  async createConversation(file: File): Promise<Conversation> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }

    const conversationId = uuidv4();

    const storageRef = ref(firebaseStorage, `conversations/${conversationId}/${file.name}`);

    try {
      await uploadBytes(storageRef, file, {customMetadata: {ownerID: user_id}});
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }

    let downloadURL: string;
    try {
      downloadURL = await getDownloadURL(storageRef);
    } catch (error) {
      console.error('Error getting download URL:', error);
      throw error;
    }

    const conversation: Conversation = {
      id: conversationId,
      name: file.name,
      ownerID: user_id,
      visibility: 'private',
      creationEpochTime: Date.now(),
      isMultiTrack: false,
      compositeMediaUrl: downloadURL,
      transcribed: false,
      transcriptID: '',
      transcriptionStatus: 'pending'
    };

    try {
      await setDoc(doc(this.conversationsCollectionRef, conversationId), conversation);
    } catch (error) {
      console.error('Error creating conversation document:', error);
      throw error;
    }

    return conversation;
  }

  async subscribeToUntranscribedConversations(
    onChange: (conversations: Conversation[]) => void,
    onError: (error: Error) => void
  ): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        const userId = await this.getCurrentUserId();
        if (!userId) {
          resolve(() => {}); // Return no-op cleanup if no user
          return;
        }

        const queryRef = query(
          this.conversationsCollectionRef,
          and(
            where('ownerID', '==', userId),
            where('transcribed', '==', false)
          ),
          orderBy('creationEpochTime', 'desc')
        );

        const unsubscribe = onSnapshot(
          queryRef,
          (snapshot) => {
            const conversations = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            } as Conversation));
            onChange(conversations);
          },
          onError
        );

        resolve(unsubscribe);
      } catch (error) {
        reject(error);
      }
    });
  }

  async subscribeToAllConversations(
    onChange: (conversations: Conversation[]) => void,
    onError: (error: Error) => void
  ): Promise<() => void> {
    const userId = await this.getCurrentUserId();
    if (!userId) {
      onChange([]);
      return () => {};
    }
  
    const user = await this.getUser(userId);
    
    // Create separate subscriptions for each access type
    const unsubscribes: (() => void)[] = [];
    
    // Public conversations
    const publicQuery = query(
      this.conversationsCollectionRef,
      where('visibility', '==', 'public'),
      orderBy('creationEpochTime', 'desc')
    );
    
    // // Owner conversations
    const ownerQuery = query(
      this.conversationsCollectionRef,
      where('ownerID', '==', userId),
      orderBy('creationEpochTime', 'desc')
    );
    
    // // Participant conversations
    const participantQuery = query(
      this.conversationsCollectionRef,
      where('participantIDs', 'array-contains', userId),
      orderBy('creationEpochTime', 'desc')
    );
  
    const queries = [publicQuery, ownerQuery, participantQuery];
    
    if (user?.isResearcher) {
      const researchQuery = query(
        this.conversationsCollectionRef,
        where('visibility', '==', 'research'),
        orderBy('creationEpochTime', 'desc')
      );
      queries.push(researchQuery);
    }
  
    // Keep track of all conversations in a map
    const conversationMap = new Map<string, Conversation>();
    
    // Function to update the combined results
    const updateResults = () => {
      const sorted = Array.from(conversationMap.values())
        .sort((a, b) => b.creationEpochTime - a.creationEpochTime);
      onChange(sorted);
    };
  
    // Subscribe to each query separately
    queries.forEach(queryRef => {
      const unsubscribe = onSnapshot(
        queryRef,
        (snapshot) => {
          snapshot.docChanges().forEach(change => {
            const conversation = {
              id: change.doc.id,
              ...change.doc.data()
            } as Conversation;
            
            if (change.type === 'removed') {
              conversationMap.delete(change.doc.id);
            } else {
              conversationMap.set(change.doc.id, conversation);
            }
          });
          
          updateResults();
        },
        onError
      );
      unsubscribes.push(unsubscribe);
    });
  
    // Return a function that unsubscribes from all queries
    return () => unsubscribes.forEach(unsubscribe => unsubscribe());
  }

  async createManuallyTranscribedConversation(mediaFile: File, transcriptFile: File): Promise<Conversation> {
    const convo = await this.createConversation(mediaFile);
    const transcriptId = uuidv4();
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
  
    const transcriptText = await transcriptFile.text();
    let transcriptData: any;
    try {
      transcriptData = JSON.parse(transcriptText);
    } catch (error) {
      throw new Error('Invalid JSON file');
    }
  
    const parseResult = TranscriptArraySchema.safeParse(transcriptData);
    if (!parseResult.success) {
      console.error('Zod parse error:', parseResult.error.format());
      throw new Error('Error parsing transcript');
    }
  
    const transcription: Transcription = {
      id: transcriptId,
      conversationID: convo.id,
      mediaPath: convo.compositeMediaUrl,
      transcript:parseResult.data
    };
  
    const transcriptionJson = JSON.stringify(transcription);
    const transcriptionBlob = new Blob([transcriptionJson], { type: 'application/json' });
  
    const storageRef = ref(firebaseStorage, transcriptionStoragePath(convo.id, transcriptId));
  
    try {
      await uploadBytes(storageRef, transcriptionBlob, {
        customMetadata: { ownerID: user_id }
      });
    } catch (error) {
      console.error('Error uploading transcript:', error);
      throw error;
    }
  
    const updatedConvo: Conversation = {
      ...convo,
      transcribed: true,
      transcriptID: transcriptId
    };

    await this.updateConversation(updatedConvo.id, updatedConvo);
    return updatedConvo;
  }

  async updateConversation(id: string, value: Conversation) {
    const docRef = doc(this.conversationsCollectionRef, id);
    await updateDoc(docRef, value);
  }

  async getConversation(id: string): Promise<Conversation | null> {
    const docRef = doc(this.conversationsCollectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data() as Conversation;
    }
    return null;
  }

  async deleteStoragePrefix(prefix: string): Promise<void[]> {
    const rootRef = ref(firebaseStorage, prefix);
    const list = await listAll(rootRef);
    
    const results = await Promise.all([
      ...list.items.map((itemRef) => deleteObject(itemRef)),
      ...(await Promise.all(
        list.prefixes.map((prefixRef) => 
          this.deleteStoragePrefix(prefixRef.fullPath)
        )
      )).flat()
    ]);
    
    return results;
  }

  async deleteConversation(id: string) {
    console.log('Deleting conversation:', id);
    const convo = await this.getConversation(id);
    if (!convo) {
      console.error('Conversation not found');
      return;
    }
    console.log('Deleting conversation:', convo);
    await this.deleteStoragePrefix(`conversations/${id}`);
    console.log('Deleted media');
    await this.deleteStoragePrefix(`transcriptions/${id}`);
    console.log('Deleted transcription');
    const groups = await this.getAnnotationGroupsFromConvoID(id);
    await Promise.all(groups.map(group => this.deleteAnnotationGroup(group.id)));
    await deleteDoc(doc(this.conversationsCollectionRef, id));
  }

  async getTranscription(conversationID: string, transcriptionID: string): Promise<Transcription | null> {
    const transcriptRef = ref(firebaseStorage, transcriptionStoragePath(conversationID, transcriptionID));
    const transcriptBytes = await getBytes(transcriptRef);
    const transcriptText = new TextDecoder().decode(transcriptBytes);
    const transcription = JSON.parse(transcriptText) as Transcription;
    return transcription;
  }

  async getDocumentSummaries(start?: QueryDocumentSnapshot<DocumentData> | null): Promise<{
    summaries: DocumentSummary[],
    last: QueryDocumentSnapshot<DocumentData> | null
  }> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      console.error('User not logged in');
      return {summaries: [], last: null};
    }
    var queryRef = query(
      this.docsCollectionRef,
      where('ownerID', '==', user_id),
      orderBy('creationEpochTime', 'desc'),
      limit(paginationLimit));
    if (start) {
      queryRef = query(queryRef, startAfter(start));
    }
    const snapshot = await getDocs(queryRef);
    return {
      summaries: snapshot.docs.map(doc => {
        return {
          id: doc.id,
          creationEpochTime: doc.data().creationEpochTime || 0,
          name: doc.data().name,
          isPublic: !!doc.data().isPublic
        };
      }),
      last: snapshot.docs.length == 0 ? null :
            snapshot.docs.length < paginationLimit ? null :
            snapshot.docs[snapshot.docs.length - 1]
    };
  }

  async getDocument(id: string): Promise<DocumentValue | null> {
    const docRef = doc(this.docsCollectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return documentDataToValue(docSnap.data());
    }
    return null;
  }

  async createDocument(name: string): Promise<DocumentSummary> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
    const docVal = {
      ownerID: user_id,
      rootNodeID: null,
      creationEpochTime: Date.now(),
      name: name,
      hoistedPath: JSON.stringify([]),
      selectedPath: JSON.stringify([]),
      hoistedNodeID: null,
      selectedNodeID: null,
      isPublic: false,
      samplingOptions: defaultSamplingOptions
    };
    const docRef = await addDoc(this.docsCollectionRef, docVal);
    return {id: docRef.id, creationEpochTime: docVal.creationEpochTime, name, isPublic: false};
  }

  async updateDocument(id: string, value: DocumentValue) {
    const docRef = doc(this.docsCollectionRef, id);
    await updateDoc(docRef, documentValueToData(value));
  }


  async deleteDocument(id: string) {
    // First, delete all nodes associated with this document
    const nodesQuery = query(this.nodesCollectionRef, where('docID', '==', id));
    const nodesSnapshot = await getDocs(nodesQuery);
    
    const deleteNodePromises = nodesSnapshot.docs.map(nodeDoc => {
      return deleteDoc(doc(this.nodesCollectionRef, nodeDoc.id));
    });

    // Wait for all node deletions to complete
    await Promise.all(deleteNodePromises);

    // Now, delete the document
    const docRef = doc(this.docsCollectionRef, id);
    await deleteDoc(docRef);
  }

  async getNode(id: string): Promise<LoomNodeValue | null> {
    if (id in this.nodeCache) {
      return this.nodeCache[id];
    }
    const docRef = doc(this.nodesCollectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let res = loomNodeDataToValue(docSnap.data());
      this.nodeCache[id] = res;
      return res;
    }
    return null;
  }

  async getChildren(documentID: string, nodeID: string): Promise<string[]> {
    if (nodeID in this.nodeChildrenCache) {
      return this.nodeChildrenCache[nodeID];
    }
    const queryRef = query(
      this.nodesCollectionRef,
      where('docID', '==', documentID),
      where('parentID', '==', nodeID),
      where('isDeleted', '==', false),
      orderBy('creationEpochTime', 'asc'));
    const querySnapshot = await getDocs(queryRef);
    querySnapshot.docs.forEach(doc => {
      this.nodeCache[doc.id] = loomNodeDataToValue(doc.data());
    });
    const res = querySnapshot.docs.map(doc => doc.id);
    this.nodeChildrenCache[nodeID] = res;
    return res;
  }

  invalidateChildCache(nodeID: string) {
    delete this.nodeChildrenCache[nodeID];
  }

  async createNode(docID: string, parentID: string | null, text: string, generatorIdentity?: 'user' | ModelType): Promise<{id: string, node: LoomNodeValue}> {
    const nodeID = uuidv4();
    const node: LoomNodeValue = {
      creationEpochTime: Date.now(),
      docID,
      parentID,
      text,
      isExpanded: true,
      isDeleted: false,
      selectedChildID: null,
      ...(generatorIdentity ? {generatorIdentity} : {})
    };
    
    await setDoc(doc(this.nodesCollectionRef, nodeID), loomNodeValueToData(node));
    return {id: nodeID, node};
  }

  async updateNode(id: string, node: LoomNodeValue): Promise<void> {
    await updateDoc(doc(this.nodesCollectionRef, id), loomNodeValueToData(node));
    this.nodeCache[id] = node;
  }

  async getUser(id: string): Promise<UserValue | null> {
    const userRef = doc(this.usersCollectionRef, id);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userDataToValue(userSnap.data());
    }
    return null;
  }

  subscribeToUser(userID: string, callback: (user: UserValue) => void) {
    const userRef = doc(this.usersCollectionRef, userID);
    
    return onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        callback(doc.data() as UserValue);
      }
    });
  }

  async getCurrentUserId(): Promise<string | null> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      return null;
    }
    return user_id;
  }

  async updateUser(value: UserValue) {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
    const userRef = doc(this.usersCollectionRef, user_id);
    await updateDoc(userRef, userValueToData(value));
  }

  async getPurchases(start?: QueryDocumentSnapshot<DocumentData> | null): Promise<{
    purchases: PurchaseValue[],
    last: QueryDocumentSnapshot<DocumentData> | null
  }> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
    var queryRef = query(
      this.purchasesCollectionRef,
      where('userID', '==', user_id),
      orderBy('startEpochTime', 'desc'),
      limit(paginationLimit));
    if (start) {
      queryRef = query(queryRef, startAfter(start));
    }
    return getDocs(queryRef).then(snapshot => {
      return {
        purchases: snapshot.docs.map(doc => {
          return purchaseDataToValue(doc.data());
        }),
        last: snapshot.docs.length == 0 ? null :
              snapshot.docs.length < paginationLimit ? null :
              snapshot.docs[snapshot.docs.length - 1]
      };
    });
  }

  async createAnnotation(annotation: Annotation): Promise<void> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
    
    await setDoc(doc(this.annotationsCollectionRef, annotation.id), annotation);
  }
  
  async getAnnotation(id: string): Promise<Annotation | null> {
    const res = await this.getAnnotations([id])
    if (res) {
      const [annotation] = res;
      return annotation
    }
    return null
  }

  async getAnnotations(ids: string[]): Promise<Annotation[] | null> {
    const annotationRefs = ids.map(id => getDoc(doc(this.annotationsCollectionRef, id)));
    const annotationDocs = await Promise.all(annotationRefs);
    const annotations = annotationDocs
      .filter(doc => doc.exists())
      .map(doc => ({ ...doc.data(), id: doc.id } as Annotation));
    
    return annotations.length > 0 ? annotations : null;
  }
  
  async updateAnnotation(id: string, annotation: Partial<Annotation>): Promise<void> {
    const docRef = doc(this.annotationsCollectionRef, id);
    await updateDoc(docRef, annotation);
  }
  
  async deleteAnnotation(id: string): Promise<{deletedGroup: boolean}> {
    const annotation = await this.getAnnotation(id);
    if (!annotation) {
      throw new Error('Annotation not found');
    }
    await deleteDoc(doc(this.annotationsCollectionRef, id));
    const annotationGroup = await this.getAnnotationGroupDBValue(annotation.groupID);
    if (annotationGroup) {
      const updatedAnnotationIDs = annotationGroup.annotationIDs.filter(annotationID => annotationID !== id);
      if (updatedAnnotationIDs.length === 0) {
        await this.forceDeleteAnnotationGroup(annotationGroup.id);
        return {deletedGroup: true};
      } else {
        await this.updateAnnotationGroup(annotationGroup.id, { annotationIDs: updatedAnnotationIDs });
        return {deletedGroup: false};
      }
      
    }
    return {deletedGroup: false};
  }

  async forceDeleteAnnotation(id: string): Promise<void> {
    await deleteDoc(doc(this.annotationsCollectionRef, id));
  }
  
  // AnnotationGroup CRUD functions
  async createAnnotationGroup(group: AnnotationGroupDBValue): Promise<void> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
    
    await setDoc(doc(this.annotationGroupsCollectionRef, group.id), group);
  }
  
  async getAnnotationGroupDBValue(id: string): Promise<AnnotationGroupDBValue | null> {
    const docRef = doc(this.annotationGroupsCollectionRef, id);
    const docSnap = await getDoc(docRef);

    if (docSnap && docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id } as AnnotationGroupDBValue;
    }
    return null;
  }
  
  async getAnnotationGroup(id: string): Promise<AnnotationGroup | null> {
    const groupDBValue = await this.getAnnotationGroupDBValue(id);
    if (!groupDBValue) {
      return null;
    }
  
    const annotations = await this.getAnnotations(groupDBValue.annotationIDs) as Annotation[] | null;
    const annoVal = annotations ? annotations : [];
  
    const { annotationIDs, ...groupWithoutAnnotationIDs } = groupDBValue;
    return {
      ...groupWithoutAnnotationIDs,
      annotations: annoVal
    };
  }
  
  async updateAnnotationGroup(id: string, group: Partial<AnnotationGroupDBValue>): Promise<void> {
    const docRef = doc(this.annotationGroupsCollectionRef, id);
    await updateDoc(docRef, group);
  }
  
  async deleteAnnotationGroup(id: string): Promise<void> {
    const group = await this.getAnnotationGroupDBValue(id);
    if (group && group.annotationIDs.length > 0) {
      // Delete all associated annotations
      const deletePromises = group.annotationIDs.map(annotationId => 
        this.forceDeleteAnnotation(annotationId)
      );
      await Promise.all(deletePromises);
    }
    if (group && !group.madeByHuman && group.promptDebugValueID) {
      await this.deleteAnnotationDebugValue(group.promptDebugValueID);
    }
    // Delete the group itself
    await deleteDoc(doc(this.annotationGroupsCollectionRef, id));
  }

  async forceDeleteAnnotationGroup(id: string): Promise<void> {
    await deleteDoc(doc(this.annotationGroupsCollectionRef, id));
  }

  async getAnnotationGroupsFromConvoID(conversationID: string): Promise<AnnotationGroup[]> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
    const queryRef = query(
      this.annotationGroupsCollectionRef,
      where('conversationID', '==', conversationID),
      orderBy('creationTime', 'desc')
    );
    
    const snapshot = await getDocs(queryRef);
    const groups: AnnotationGroup[] = [];
    for (const doc of snapshot.docs) {
      const group = await this.getAnnotationGroup(doc.id);
      if (group) {
        groups.push(group);
      }
    }
  
    return groups;
  }

  async getAnnotationDebugValue(debugId: string): Promise<AnnotationPromptDebugValue | null> {
    const debugRef = ref(firebaseStorage, `annotationPromptDebugValues/${debugId}.json`);
    try {
      const debugBytes = await getBytes(debugRef);
      const debugText = new TextDecoder().decode(debugBytes);
      const debugData = JSON.parse(debugText);
      const parsed = annotationPromptDebugValueSchema.safeParse(debugData);
      if (!parsed.success) {
        return null;
      }
      return parsed.data;
    } catch (error) {
      return null;
    }
  }
  
  async createAnnotationDebugValue(value: AnnotationPromptDebugValue): Promise<void> {
    const user_id = (await getCurrentUser())?.uid;
    if (!user_id) {
      throw new Error('User not logged in');
    }
  
    const debugJson = JSON.stringify(value);
    const debugBlob = new Blob([debugJson], { type: 'application/json' });
    const debugRef = ref(firebaseStorage, `annotationPromptDebugValues/${value.id}.json`);
    await uploadBytes(debugRef, debugBlob, {
      customMetadata: { 
        ownerID: user_id,
        conversationID: value.conversationID
      }
    });
  }
  
  async deleteAnnotationDebugValue(debugId: string): Promise<void> {
    const debugRef = ref(firebaseStorage, `annotationPromptDebugValues/${debugId}.json`);
    await deleteObject(debugRef);
  }

  async unlinkDiscordAccount(userId: string): Promise<void> {
    const userRef = doc(this.usersCollectionRef, userId);
    await updateDoc(userRef, { 
        discordInfo: deleteField()
    });
  }

  /**
   * Updates the Transcription document in storage.
   * This function is a stub—replace it with your actual update logic.
   */
  async updateTranscription(conversationID: string, transcription: Transcription): Promise<void> {
    try {
      // Generate the proper storage path using the utility function.
      const finalFileName = transcriptionStoragePath(transcription.conversationID, transcription.id);
      // Create a storage reference for the file.
      const storageRef = ref(firebaseStorage, finalFileName);
  
      // Convert the transcription object to JSON,
      // then create a Blob from it.
      const jsonContent = JSON.stringify(transcription, null, 2);
      const blob = new Blob([jsonContent], { type: 'application/json' });
  
      // Get a timestamp and current user (to be used as ownerID)
      const timestamp = Date.now();
      const currentUser = firebaseAuth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user found');
      }
      const ownerID = currentUser.uid;
  
      // Set the metadata (note: front end uses 'customMetadata')
      const metadata = {
        contentType: 'application/json',
        customMetadata: {
          lastUpdatedAt: String(timestamp),
          ownerID: ownerID,
        }
      };
  
      // Upload the file to storage. This will overwrite any existing file.
      await uploadBytes(storageRef, blob, metadata);
    } catch (error) {
      console.error('Error updating transcription:', error);
      throw new Error(
        `Failed to update transcription for conversation ${conversationID}: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  }
}
